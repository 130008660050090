import PropTypes from "prop-types";
import Image from "next/image";
import cn from "classnames";
import { imgix } from "@utils/imgix";
import { ImagePlaceholder } from "@components/imagePlaceholder";

// URL.parse() is deprecated, so we're using the URL constructor
// and a try/catch block to handle invalid URLs
function getURL(src) {
  try {
    return new URL(src);
  } catch (error) {
    return null;
  }
}

export function imgixLoader({ src, width, quality }) {
  const { href = "", searchParams = new URLSearchParams() } = getURL(src) || {};

  // adding our defaults to the final param record
  const params = new URLSearchParams([
    ...searchParams.entries(),
    ...Object.entries({
      ...(searchParams.has("w") ? {} : { w: width }),
      ...(searchParams.has("auto") ? {} : { auto: "format" }),
      q: quality || 75,
    }),
  ]).toString();

  const loaderSrc = `${href.split("?")[0]}?${params}`;
  return loaderSrc;
}

export function ImgixNextImage({
  className = null,
  src = null,
  alt = "",
  width = null,
  height = null,
  quality = null,
  style = {},
  priority = false,
  imgixParams = {},
  loader = null,
  type,
  sizes,
}) {
  const { href = "", searchParams = new URLSearchParams() } = getURL(src) || {};
  // Combining all the URL params that are being passed through
  // and allowing imgixParams to override any that may already exist on the URL
  const params = new URLSearchParams([
    ...searchParams.entries(),
    ...Object.entries(imgixParams),
  ]).toString();

  if (!src)
    return (
      <ImagePlaceholder
        {...{
          className,
          alt,
          width,
          height,
          priority,
          srcParams: `?${params}`,
          type,
        }}
      />
    );

  // check if image is using imgix path
  // then add combined params
  // if not pass the image source with initial params from the URL
  const imgixSrc = imgix(src);
  const isImgixUrl = imgixSrc.includes("imgix");
  const imageSrc = isImgixUrl ? `${href.split("?")[0]}?${params}` : src;

  return (
    <Image
      {...(isImgixUrl ? { loader: imgixLoader } : { loader })}
      src={imageSrc}
      alt={alt || ""}
      className={cn(
        "max-w-full",
        { "object-cover": !className?.includes("object-") },
        className
      )}
      width={width}
      height={height}
      quality={quality}
      style={style}
      priority={priority}
      {...(sizes
        ? {
            sizes,
          }
        : {})}
    />
  );
}

ImgixNextImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({}),
  // for better performance do not define quality via imgix,
  // instead use the quality prop
  imgixParams: PropTypes.shape({}),
  priority: PropTypes.bool,
  quality: PropTypes.number,
  ...ImagePlaceholder.propTypes,
};
